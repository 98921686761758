<template>
  <v-card elevation="0">
    <v-card-title>{{ title }}</v-card-title>
    <v-card-text>
      <form
        v-if="isInitial || isSaving"
        enctype="multipart/form-data"
        novalidate
      >
        <div v-if="!single || fileList.length === 0" class="dropbox">
          <input
            :key="fileInputKey"
            type="file"
            :multiple="!single"
            :name="uploadFieldName"
            :disabled="isSaving"
            :accept="mime"
            class="input-file"
            @change="
              filesChange($event.target.name, $event.target.files);
              fileCount = $event.target.files.length;
            "
          />
          <p v-if="isInitial">
            {{ $t('upload.drag-files') }}
          </p>
          <p v-if="isSaving">
            {{ $t('upload.uploading-files', { count: fileCount }) }}
          </p>
        </div>
      </form>
      <file
        v-for="f in fileList"
        :key="f.id"
        :file="f"
        :status="statuses[f.id] || 'new'"
        @remove="onRemove"
      />
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="primary"
        :disabled="!fileList.length"
        text
        @click="onUpload"
      >
        {{ uploadText }}
      </v-btn>
      <v-btn color="primary" text @click="onCancel">
        {{ closeLabel }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
const STATUS_INITIAL = 0,
  STATUS_SAVING = 1;

import File from './fileToUpload.vue';
import { v4 as uuidv4 } from 'uuid';

export default {
  components: {
    File
  },
  props: {
    title: {
      type: String,
      default: 'Upload'
    },
    statuses: {
      type: Array,
      default: () => []
    },
    mime: {
      type: String,
      default: 'application/pdf'
    },
    uploadLabel: {
      type: String,
      default: null
    },
    single: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      currentStatus: null,
      fileList: [],
      uploadFieldName: 'file',
      fileInputKey: 0,
      wasUpload: false
    };
  },

  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    closeLabel() {
      if (this.wasUpload) {
        return this.$t('close');
      }
      return this.$t('cancel');
    },
    uploadText() {
      if (this.uploadLabel === null) {
        return this.$t('upload.upload');
      }
      return this.uploadLabel;
    }
  },
  mounted() {
    this.reset();
  },
  activated() {
    this.reset();
  },
  methods: {
    onCancel() {
      this.reset();
      this.$emit('close');
    },
    async onUpload() {
      const toUpload = this.fileList.filter(
        (file) => this.statuses[file.id] != 'done'
      );
      console.log('to upload:', toUpload);
      this.$emit('upload', toUpload);
      this.wasUpload = true;
    },

    reset() {
      this.currentStatus = STATUS_INITIAL;
      this.wasUpload = false;
      this.fileList = [];
    },

    filesChange(fieldName, fileList) {
      console.log('fileList: ', fileList);
      this.fileList = this.fileList.concat(
        Array.from(Array(fileList.length).keys()).map((x) => {
          return {
            id: uuidv4(),
            file: fileList[x]
          };
        })
      );
      console.log('fileList: ', this.fileList);
      this.fileInputKey++; // to clear the input
    },

    onRemove(file) {
      console.log('onRemove:', file, 'fileList:', this.fileList);
      this.fileList = this.fileList.filter((x) => x.id != file.id);
    }
  }
};
</script>

<style lang="scss">
.dropbox {
  outline: 2px dashed grey;
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px;
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0;
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue;
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>
